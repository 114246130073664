import { Show, createEffect, createSignal, splitProps } from "solid-js";
import { FormControlData, FormControlValue, PhoneInputViewProps } from "../model";
import { createFormControl } from "solid-forms";
import { SHARED_CONST } from ":mods";

export function PhoneInput<V extends FormControlValue, D extends FormControlData>({
  control,
  onCountryCodeSelected,
  countryCodeControl,
  ...props
}: PhoneInputViewProps<V, D>) {
  const COUNTRY = {
    name: {
      en: "Saudi Arabia",
      ar: "السعودية",
    },
    iso2: "SA",
    iso3: "SAU",
    currency: {
      SAR: {
        name: "Saudi riyal",
        symbol: "ر.س",
      },
    },
    country_code: "+966",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/sa.png",
      svg: "https://flagcdn.com/sa.svg",
      alt: "The flag of Saudi Arabia has a green field, at the center of which is an Arabic inscription — the Shahada — in white above a white horizontal sabre with its tip pointed to the hoist side of the field.",
    },
  };
  const [countriesList, setCountriesList] = createSignal(SHARED_CONST.COUNTRIES_LIST);
  const [selectedCntry, setSelectedCntry] = createSignal(SHARED_CONST.COUNTRIES_LIST.find((c) => c.iso2 == "SA"));
  const [showMenu, setShowMenu] = createSignal(false);
  const _control = Array.isArray(control) ? createFormControl<V, D>(...control) : control;

  const handleMenuShow = () => {
    setShowMenu(showMenu() ? false : true);
  };
  const onSelectCountry = ({ target }) => {
    const { id } = target.parentElement || {};
    const country = countriesList().find((country) => country.iso2 == id);
    setSelectedCntry(country);
    var value = _control.value as string;
    // value = value
    _control.setValue(`${value}` as any);
    onCountryCodeSelected?.(country.country_code);
    setShowMenu(false);
  };
  const onSearchCountry = ({ target }) => {
    const { value } = target || {};
    const filteredList = SHARED_CONST.COUNTRIES_LIST.filter(
      (country) => country.name.en.toLowerCase().includes(value) || country.name.ar.toLowerCase().includes(value)
    );
    setCountriesList(filteredList);
  };
  const removeAllCharsFromNumber = (str: string) => {
    return str.replace(/[^0-9]/g, "");
  };
  const onPhoneNumberChange = (e) => {
    var value = removeAllCharsFromNumber(e.currentTarget.value as string);
    _control.setValue(value as V);
  };
  createEffect(() => {
    if (!!countryCodeControl.value) {
      const country = SHARED_CONST.COUNTRIES_LIST.find((c) => c.country_code == countryCodeControl.value);
      setSelectedCntry(country);
      return;
    }
    const country = SHARED_CONST.COUNTRIES_LIST.find((c) => c.country_code == COUNTRY.country_code);
    setSelectedCntry(country);
    onCountryCodeSelected?.(country.country_code);
  });
  return (
    <section id="country_code_section">
      <div class={`flex items-center gap-10px w-full bg-inherit ${props.class}`}>
        {/* country flag and clickable section */}
        <div class="flex bg#paper w-50px h-25px cursor-pointer items-center" onClick={handleMenuShow}>
          <input
            name="country_code"
            value={COUNTRY.country_code}
            hidden
            readOnly
            class={`bg#paper w-30px ${props.inputClass ?? ""}`}
          />
          {/* <img src={selectedCntry().flags?.svg} class="w-28px" /> */}
          <i class={`icon-local:arrow-right w-23px h-23px rotate-90`} />
        </div>

        <p class="text-14px">{`(${selectedCntry().country_code})`}</p>
        {/* the phone number input */}
        <input
          class="bg-inherit text-16px w-full"
          placeholder="5xxxxxxxx"
          type="tel"
          maxLength={selectedCntry().iso2 == "SA" ? 9 : 15}
          classList={{
            invalid: !!_control.errors,
            touched: _control.isTouched,
            disabled: _control.isDisabled,
            // required: control.isRequired,
          }}
          onChange={() => {}}
          onchange={() => {}}
          onInput={onPhoneNumberChange}
          onblur={() => _control.markTouched(true)}
          onfocus={() => _control.markTouched(true)}
          onfocusin={() => _control.markTouched(true)}
          onfocusout={() => _control.markTouched(false)}
          disabled={_control.isDisabled}
          required={_control.isRequired}
          value={_control.value}
        />
      </div>
      {/* the drop down */}
      <Show when={showMenu()}>
        <div id="countries_list" class="-mt-10px border-2 border#p border-solid">
          <input onInput={onSearchCountry} placeholder="search..." class="w-full h-full p-12px bg-white text-16px " />
          <ul class="list-none text-16px my-8px h-200px overflow-auto px-12px bg-inherit">
            {countriesList().length > 0 ? (
              countriesList()?.map((country) => {
                return (
                  <li
                    class={`flex gap-20px py-10px cursor-pointer ${
                      country?.iso2 == selectedCntry()?.iso2 && "-ml-18px pl-18px bg#n-50"
                    }`}
                    onClick={onSelectCountry}
                    id={country.iso2}
                  >
                    <span class="w-34px">{country.country_code}</span> <span class="w-full">{country.name.en}</span>
                  </li>
                );
              })
            ) : (
              <p class="py-10px">No Countries Found</p>
            )}
          </ul>
        </div>
      </Show>
    </section>
  );
}
